import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, DataTypeEnum, EntityTypeFieldDto, ModuleKeywords, PolicyDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { PublishedPolicyDataService } from '../../service/data/published-policy-data.service';

@Component({
  selector: 'app-published-policy-item-form',
  templateUrl: './published-policy-item-form.component.html',
  styleUrl: './published-policy-item-form.component.scss',
})
export class PublishedPolicyItemFormComponent extends BaseForm<PolicyDto> implements OnInit {
  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];
  dataTypeEnum = DataTypeEnum;
  formVal;
  // policyBodyForm:FormGroup ;
  constructor(
    public viewModeService: ViewModeService,
    public policyDataService: PublishedPolicyDataService,
    private router: Router
  ) {
    super(viewModeService, ModuleKeywords.PublishedPolicy);
  }

  ngOnInit(): void {
    this.formVal = this.formValid$;
    setTimeout(() => {
      this.formValid$.subscribe((res) => {});
    }, 10000);
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    // this.policyBodyForm.patchValue({...data.policyBody});
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      approvers: new FormControl(null, Validators.required),
    });
  }
  // resetFormGroup() {
  //   this.formGroup.reset();
  // }
}

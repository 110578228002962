<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid pt-2">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="'view'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="'view'"
      ></app-text-editor>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [principleModeSelection]="'SELECTION'"
        [label]="'Approvers'"
        [placeholder]="'Search...'"
        [control]="formGroup?.controls?.approvers"
        [multi]="true"
        [viewMode]="'view'"
        [selectedEntities]="data?.approvers"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && (data?.policyStatus == 'DRAFT' || !data?.policyStatus)) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton && (data?.policyStatus == 'DRAFT' || !data?.policyStatus)) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton && (data?.policyStatus == 'DRAFT' || !data?.policyStatus)) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
